export const DEFAULT_YEAR_MONTH_DATE_FORMAT = "yyyy-MM-dd";
export const DEFAULT_DATE_MONTH_YEAR_FORMAT = "dd-MM-yyyy";
export const DAY_DATE_TIME_COMPLETE_FORMAT = "EEEE - dd/MM/yyyy - hh:mm a";
export const DATE_YEAR_MONTH_DAY_TIME_FORMAT = "yyyy-MM-dd, hh:mm a";
export const DAYS_MONTH_YEAR_FORMAT = "dd/MM/yyyy";
export const DATE_MONTH_TIME_FORMAT = "dd MMM yyyy, hh:mm aa";
export const DATE_WITH_FULL_MONTH_FORMAT = "PPP";
export const DEFAULT_TIME_FORMAT_12 = "hh:mm a";
export const DATE_FILTER = "LLL dd,yyyy";
export const DATE_MONTH_YEAR_FORMAT_VALUE = "dd.MM.yyyy";
